<div class="card">
  <div class="card-body">
    <div class="media">
      <div class="me-3">
        <i class="avatar-md rounded-circle img-thumbnail mdi mdi-account-tie py-0 px-2 font-size-48"
          style="font-size: 48px"></i>
      </div>
      <div class="media-body align-self-center">
        <div>
          <h5 class="mb-0">Cliente Titular
          </h5>
          <p class="mb-0">{{mainDebtorClientName }}</p>
          <p class="mb-0">{{mainDebtorClientRut }}</p>
          <span class="text-muted">SOLICITUD N° <strong>{{solicitationId && solicitationId}}</strong>{{mutualCode && " / MUTUO N° "}}<strong>{{mutualCode && mutualCode}}</strong></span>
        </div>
      </div>
    </div>
  </div>
</div>

