import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AssociatedClientRestService } from '../../../services/rest/associated-client-rest.service';
import { UtilService } from '../../../services/common/util.service';
import { AssociatedClientModel } from '../../../models/associated-client.model';
import { AssociatedClientTypeEnum } from 'src/app/enums/associated-client-type.enum';
import { EnumModel } from 'src/app/models/enum.model';
import { RUT_MASK } from 'src/app/config/globals-constants';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {

 //Properties
 @Input() solicitationId: number;
 @Input() mutualCode: string;
 mainDebtor: AssociatedClientModel;
 mainDebtorClientRut: string;
 mainDebtorClientName: string;

 //Subscriptions
 subscription$: Subscription;

 constructor(private associatedClientRestService: AssociatedClientRestService) { }

 ngOnInit(): void {
   this.loadHeader();
 }

 ngOnDestroy(): void {
   this.subscription$?.unsubscribe();
 }

 loadHeader(): void {
   this.subscription$ = this.associatedClientRestService.findAssociatedClient(this.solicitationId).subscribe((response: AssociatedClientModel[]) => {
    this.mainDebtor = response.find(r => (r.associatedClientTypeEnum as EnumModel).id === AssociatedClientTypeEnum.DEUDOR); 
    this.mainDebtorClientRut = UtilService.formatRut(this.mainDebtor?.client?.rut, this.mainDebtor?.client?.digit);
    this.mainDebtorClientName = `${this.mainDebtor?.client?.name} ${this.mainDebtor?.client?.lastName} ${this.mainDebtor?.client?.lastNameMother}`;
   });
 }

}
