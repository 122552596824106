import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PROFILE_URL } from 'src/app/config/globals-constants';
import { RequestUserRolModel } from 'src/app/models/request-user-rol.model';
import { RequestUser } from 'src/app/models/request-user.model';
import { BaseService } from './base-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileRestService extends BaseService {

  getMenuAccesses(): Observable<any[]> {
    let requestUser;
    requestUser = {
      username: localStorage.getItem('username')
    } as RequestUser;
    return this.httpClient.post<any[]>(PROFILE_URL + '/access-menu', requestUser, this.optionsProfileService);
  }

  getProfilePlus(): Observable<any> {
    let requestUser = {
      username: localStorage.getItem('username')
    } as RequestUser;
    return this.httpClient.post<any>(PROFILE_URL + '/profile-plus', requestUser, this.optionsProfileService);
  }

  verifyRolByUsername(requestUserRolModel: RequestUserRolModel): Observable<any> {
    return this.httpClient.post<any>(PROFILE_URL + '/verify-rol', requestUserRolModel, this.optionsProfileService);
  }
}
