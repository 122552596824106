import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LOCALE_ID, NgModule} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// import { environment } from '../environments/environment';
import {NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbActiveModal, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import { LayoutsModule } from './layouts/layouts.module';
import { AppComponent } from './app.component';
// import { initFirebaseBackend } from './authUtils';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import {CommonModule, registerLocaleData} from '@angular/common';
import localeCl from '@angular/common/locales/es-CL';
import { DevelopmentTableRecalculatorComponent } from './pages/development-table/development-table-recalculator/development-table-recalculator.component';
import {MatTableModule} from '@angular/material/table';

// Rutas principales
import { AppRoutingModule } from './app-routing.module';
import { RefreshTokenInterceptor } from './core/helpers/refresh-token-interceptor';

registerLocaleData(localeCl, 'es-CL');


// if (environment.defaultauth === 'firebase') {
//   initFirebaseBackend(environment.firebaseConfig);
// } else {
//   FakeBackendInterceptor;
// }


@NgModule({
  declarations: [
    AppComponent,
    DevelopmentTableRecalculatorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    CommonModule,
    MatTableModule,
    NgbDropdownModule
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-CL'},
    NgbActiveModal
  ]
})
export class AppModule { }
