<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/pages/dashboards" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-hsp.jpg" alt="" height="50" width="150">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-hsp.jpg" alt="" height="50" width="150">
          </span>
        </a>

        <a routerLink="/pages/dashboards" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-hsp.jpg" alt="" class="rounded" height="50" width="150">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-hsp.jpg" alt=""  class="rounded" height="50" width="150">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <span style="color: antiquewhite;">
          <i class="fas fa-stopwatch fa-fw"></i> {{session}}
        </span>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button (click)="logout()" ngbDropdownItem>Cerrar Sesion</button>
          </div>
          <img class="rounded-circle header-profile-user" src="assets/images/avatar.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1" *ngIf="authService.isLogged; else elseBlock">{{userName}}</span>
          <ng-template #elseBlock>
            <span class="d-none d-xl-inline-block ms-1">{{userName}}</span>
          </ng-template>
        </button>
      </div>
    </div>
  </div>
</header>

<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="item.subAccesses.length === 0"
                href="javascript: void(0);" id="topnav-components" [routerLink]="item.url" routerLinkActive="active"
                role="button" style="display: flex; align-items: center;">
                <i class="bx {{item.icon}} me-1 font-size-14"></i>{{ item.description }}
              </a>
              <a *ngIf="item.subAccesses && item.subAccesses.length > 0" class="nav-link dropdown-toggle arrow-none"
                 href="javascript: void(0);" id="topnav-components" role="button"
                [routerLink]="item.url" style="display: flex; align-items: center;">
                <i class="bx {{item.icon}} me-1 font-size-14"></i> {{ item.description }} <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu row" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template #recursiveList let-subAccesses="subAccesses">
                  <ng-container *ngFor="let sub of subAccesses">
                    <a class="col dropdown-item side-nav-link-ref" *ngIf="!hasItems(sub)" href="javascript: void(0);"
                      [routerLink]="sub.url" routerLinkActive="active">{{ sub.description }}
                    </a>
                    <div class="dropdown px-0" *ngIf="hasItems(sub)">
                      <a class="dropdown-item dropdown-toggle arrow-none" href="javascript: void(0);"
                         *ngIf="hasItems(sub)" [routerLink]="sub.url" routerLinkActive="active">
                        {{ sub.description }} <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="topnav-dashboard" *ngIf="hasItems(sub)">
                          <ng-container *ngTemplateOutlet="recursiveList; context:{ subAccesses: sub.subAccesses }">
                          </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-container *ngTemplateOutlet="recursiveList; context:{ subAccesses: item.subAccesses }">
                </ng-container>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>
