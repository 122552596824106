import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthenticationService} from '../../authentication/services/authentication.service';
import {UtilService} from '../../services/common/util.service';
import { LOGIN_ERROR, UTM_ERROR } from 'src/app/config/globals-constants';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError(err => {
        console.log(err)
        if (err.status === 401) {
            // auto logout if 401 response returned from api
            this.authenticationService.logout();
            //location.reload();
        }
        let error = err?.error?.message|| err?.message || err?.statusText;

        if(error=='Http failure response for '+ environment.host+environment.basePathInsurancePolicyApi+'/insurance-liquidation: 500 OK'){
          error="No puede repetir un seguro";
        }

        if(error==LOGIN_ERROR){
          error="Usuario y/o contraseña incorrecta";
        }
        console.log(error);

        if(error==UTM_ERROR){
          error="No hay info UTM en la CMF";
        }
        (error) ? UtilService.getSweetAlertTop(error, 'error') : UtilService.getSweetAlertTop('Se han producido un error', 'error');
        return of(err);
      }));
    }
}
