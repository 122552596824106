import { EnumModel } from '../models/enum.model';

export enum AssociatedClientTypeEnum {
  DEUDOR = 1,
  AVAL = 2
}

export class AssociatedClientType {
  private readonly _DEUDOR: EnumModel;
  private readonly _AVAL: EnumModel;
  private readonly _ASSOCIATED_CLIENT_TYPE: EnumModel[];

  constructor() {
    this._DEUDOR = {
      id: AssociatedClientTypeEnum.DEUDOR,
      description: 'DEUDOR'
    };
    this._AVAL = {
      id: AssociatedClientTypeEnum.AVAL,
      description: 'AVAL'
    };
    this._ASSOCIATED_CLIENT_TYPE = [
      this._AVAL,
      this._DEUDOR
    ] as EnumModel[];
  }

  get DEUDOR(): EnumModel {
    return this._DEUDOR;
  }

  get AVAL(): EnumModel {
    return this._AVAL;
  }

  get ASSOCIATED_CLIENT_TYPE(): EnumModel[] {
    return this._ASSOCIATED_CLIENT_TYPE;
  }
}
