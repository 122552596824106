import { FileTypeEnumModel } from '../models/file-type-enum.model';

export enum FileTypeEnum {
  TXT = 1,
  PDF = 2,
  DOC = 3,
  XLS = 4,
  PNG = 5,
  JPG = 6,
  TIFF = 7,
  BMP = 8,
  ZIP = 9,
  RAR = 10,
  TAR = 11,
  SEVEN_Z = 12
}

export class FileType {
  private readonly _TXT: FileTypeEnumModel;
  private readonly _PDF: FileTypeEnumModel;
  private readonly _DOC: FileTypeEnumModel;
  private readonly _XLS: FileTypeEnumModel;
  private readonly _PNG: FileTypeEnumModel;
  private readonly _JPG: FileTypeEnumModel;
  private readonly _TIFF: FileTypeEnumModel;
  private readonly _BMP: FileTypeEnumModel;
  private readonly _ZIP: FileTypeEnumModel;
  private readonly _RAR: FileTypeEnumModel;
  private readonly _TAR: FileTypeEnumModel;
  private readonly _SEVEN_Z: FileTypeEnumModel;
  private readonly _FILE_TYPE: FileTypeEnumModel[];

  constructor() {
    this._TXT = {
      id: FileTypeEnum.TXT,
      contentType: 'text/plain',
      extension: 'txt'
    };
    this._PDF = {
      id: FileTypeEnum.PDF,
      contentType: 'application/pdf',
      extension: 'pdf'
    };
    this._DOC = {
      id: FileTypeEnum.DOC,
      contentType: 'application/msword',
      extension: 'doc'
    };
    this._XLS = {
      id: FileTypeEnum.XLS,
      contentType: 'application/vnd.ms-excel',
      extension: 'xls'
    };
    this._PNG = {
      id: FileTypeEnum.PNG,
      contentType: 'image/png',
      extension: 'png'
    };
    this._JPG = {
      id: FileTypeEnum.JPG,
      contentType: 'image/jpeg',
      extension: 'jpg'
    };
    this._TIFF = {
      id: FileTypeEnum.TIFF,
      contentType: 'image/tiff',
      extension: 'tiff'
    };
    this._BMP = {
      id: FileTypeEnum.BMP,
      contentType: 'image/bmp',
      extension: 'bmp'
    };
    this._ZIP = {
      id: FileTypeEnum.ZIP,
      contentType: 'application/zip',
      extension: 'zip'
    };
    this._RAR = {
      id: FileTypeEnum.RAR,
      contentType: 'application/x-rar-compressed',
      extension: 'rar'
    };
    this._TAR = {
      id: FileTypeEnum.TAR,
      contentType: 'application/x-tgz',
      extension: 'tar'
    };
    this._SEVEN_Z = {
      id: FileTypeEnum.SEVEN_Z,
      contentType: 'application/x-7z-compressed',
      extension: '7z'
    };

    this._FILE_TYPE = [
      this._TXT,
      this._PDF,
      this._DOC,
      this._XLS,
      this._PNG,
      this._JPG,
      this._TIFF,
      this._BMP,
      this._ZIP,
      this._RAR,
      this._TAR,
      this._SEVEN_Z
    ] as FileTypeEnumModel[];
  }

  get TXT(): FileTypeEnumModel {
    return this._TXT;
  }
  get PDF(): FileTypeEnumModel {
    return this._PDF;
  }
  get DOC(): FileTypeEnumModel {
    return this._DOC;
  }
  get XLS(): FileTypeEnumModel {
    return this._XLS;
  }
  get PNG(): FileTypeEnumModel {
    return this._PNG;
  }
  get JPG(): FileTypeEnumModel {
    return this._JPG;
  }
  get TIFF(): FileTypeEnumModel {
    return this._TIFF;
  }
  get BMP(): FileTypeEnumModel {
    return this._BMP;
  }
  get ZIP(): FileTypeEnumModel {
    return this._ZIP;
  }
  get RAR(): FileTypeEnumModel {
    return this._RAR;
  }
  get TAR(): FileTypeEnumModel {
    return this._TAR;
  }
  get SEVEN_Z(): FileTypeEnumModel {
    return this._SEVEN_Z;
  }
  get FILE_TYPE(): FileTypeEnumModel[] {
    return this._FILE_TYPE;
  }
}
