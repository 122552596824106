import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Componente del módulo principal para el diseño de la plataforma
import { LayoutComponent } from './layouts/layout.component';
import { AuthenticationGuard } from './authentication/guards/authentication.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'pages',
    component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes /*, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' }*/)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
