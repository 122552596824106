export const environment = {
  production: false,
  defaultauth: '',
  // host: 'https://190.243.30.34:4200/hs-desarrollo/hipotecarios',
  host: 'https://api-des.securityprincipal.cl:4200/hs-desarrollo/hipotecarios',
  apiKey: '551b49b8-fe16-463c-be66-7e219b7ed38d',
  homeRoute: 'https://hs-as-acc-front-des.azurewebsites.net/auth/login',
  loginErrorRoute: 'Http failure response for https://des-sso.security.cl:8543/auth/realms/HS-Internals/protocol/openid-connect/token: 401 OK',
  cmfErrorRoute: 'Http failure response for https://api.sbif.cl/api-sbifv3/recursos_api/utm?apikey=eeca503d1a029d14951c5af43ca9302729daf297&formato=json: 404 No Encontrado',
  basePathSimulatorApi:'/Simulator',
  basePathSolicitationApi:'/Solicitation',
  basePathInsurancePolicyApi:'/Policy',
  basePathDevelopmentTableApi:'/DevelopmentTable',
  basePathJudicialLiquidationApi:'/Liquidation',
  basePathMutualApi:'/Mutual',
  basePathProfileApi:'/Profile',
  SSOBaseUrl: 'https://des-sso.security.cl:8543/auth/realms/',
  profileClientId: '555a947c-b025-4609-ba61-a8ded605b583',
  simulatorClientId: '94067f09-ac67-45a1-8fd3-31771c5e11ab',
  solicitationClientId: '69ff432e-e015-4f3a-9d79-a6bf0cca83d0',
  insurancePolicyClientId: 'f3cb331b-bdde-43a8-b9f0-b636b89f3e53',
  judicialLiquidationClientId: 'b070935a-a687-4a96-b21d-e4838e3a6318',
  mutualClientId: '9fdeecbe-3eec-494c-a697-c5a0129c6990',
  developmentTableClientId: 'ecda4e2c-08cb-4538-8aff-b285dc39b98f',
  dividendClientId: '6e5230c4-680d-44c6-a369-192cbc5fb0d1',
  financialPortabilityClientId: '18b10025-74fe-4448-9e0d-ba2aa246d35b',
  hubspotClientId: '13fa1d87-6530-4f9c-af36-134741744959',
  tmcClientId: 'a8dc935f-40f6-483e-b286-2291bb0fa34a',
  wsKtaClientId: '4a6bad5c-f719-430e-8a68-5d2b8c14e861'
};
