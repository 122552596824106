import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    protected httpClient: HttpClient,
    protected authService: AuthenticationService
  ) { }

  get options(): { headers: HttpHeaders } {
    return this.authService.headers;
  }

  get optionsSimulationService(): { headers: HttpHeaders } {
    return this.authService.headersSimulationService;
  }

  get optionsProfileService(): { headers: HttpHeaders } {
    return this.authService.headersProfileService;
  }

  get optionsSolicitationService(): { headers: HttpHeaders } {
    return this.authService.headersSolicitationService;
  }

  get optionsInsurancePolicyService(): { headers: HttpHeaders } {
    return this.authService.headersInsurancePolicyService;
  }

  get optionsJudicialLiquidationService(): { headers: HttpHeaders } {
    return this.authService.headersJudicialLiquidationService;
  }

  get optionsMutualService(): { headers: HttpHeaders } {
    return this.authService.headersMutualService;
  }

  get optionsDevelopmentTableService(): { headers: HttpHeaders } {
    return this.authService.headersDevelopmentTableService;
  }
  
  get optionsDividendService(): { headers: HttpHeaders } {
    return this.authService.headersDividendService;
  }

  get optionsFinancialPortabilityService(): { headers: HttpHeaders } {
    return this.authService.headersFinancialPortabilityService;
  }

  get optionsHubspotService(): { headers: HttpHeaders } {
    return this.authService.headersHubspotService;
  }

  get optionsTmcService(): { headers: HttpHeaders } {
    return this.authService.headersTmcService;
  }

  get optionsWsKtaService(): { headers: HttpHeaders } {
    return this.authService.headersWsKtaService;
  }
}
