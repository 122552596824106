import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { DevelopmentTableRestService } from '../../../services/rest/development-table-rest.service';
import { DevelopmentTableRecalculatedModel } from '../../../models/development-table-recalculated.model';
import Swal from 'sweetalert2';
import { QuotaModel } from '../../../models/quota.model';
import { MatSort } from '@angular/material/sort';
import { EnumModel } from '../../../models/enum.model';
import { FactorTableDetailModel } from '../../../models/factor.table.detail.model';
import { UtilService } from '../../../services/common/util.service';

@Component({
  selector: 'app-development-table-recalculator',
  templateUrl: './development-table-recalculator.component.html',
  styleUrls: ['./development-table-recalculator.component.scss']
})
export class DevelopmentTableRecalculatorComponent implements OnInit {
  @Input() mutualId: number;
  @Input() mutualCode: string;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public dataSource: MatTableDataSource<QuotaModel>;
  public dtRecalculated: DevelopmentTableRecalculatedModel;
  public displayColumns = ['number', 'capital', 'interest', 'dividend', 'outstandingBalance', 'amortisation', 'expiration', 'payment'];

  constructor(public modal: NgbActiveModal,
    public developmentTableService: DevelopmentTableRestService
  ) { }

  ngOnInit(): void {
    this.dataSource = null;
    this.calculate(this.mutualId);
  }

  calculate(mutualId: number): void {
    this.developmentTableService.calculate(mutualId).subscribe(
      (response: DevelopmentTableRecalculatedModel) => {
        if (response != null) {
          this.dataSource = new MatTableDataSource<QuotaModel>(response.quotas);
          this.dtRecalculated = response;
          this.dataSource.sort = this.sort;
        }
      });
    return null;
  }

  save(): void {
    UtilService.getSweetLoading('Actualizando Tabla ...');
    const array: FactorTableDetailModel[] = [];
    for (let n = 0; n < this.dtRecalculated.factorTableDetails.length; n++) {
      const q: FactorTableDetailModel = this.dtRecalculated.factorTableDetails[n];
      q.factorTableTypeEnum = (q.factorTableTypeEnum as EnumModel).id as number;
      array.push(q);
    }

    if (array.length > 0) {
      this.dtRecalculated.factorTableDetails = array;
    }
    if (this.dtRecalculated.additionalFactorTableDetails != null && this.dtRecalculated.additionalFactorTableDetails.length > 0) {
      const arr: FactorTableDetailModel[] = [];
      for (let n = 0; n < this.dtRecalculated.additionalFactorTableDetails.length; n++) {
        const q: FactorTableDetailModel = this.dtRecalculated.additionalFactorTableDetails[n];
        q.factorTableTypeEnum = (q.factorTableTypeEnum as EnumModel).id as number;
        arr.push(q);
      }

      if (arr.length > 0) {
        this.dtRecalculated.additionalFactorTableDetails = arr;
      }
    }

    this.developmentTableService.save(this.dtRecalculated).subscribe(
      (response) => {
        Swal.fire('¡Felicidades!', 'Ha modificado correctamente la tabla de desarrollo del mutuo - ' + this.mutualCode, 'success');
        this.dtRecalculated = null;
        this.dataSource.data = [];
      }
    );
  }

  @HostListener('scroll', ['$event'])
  doScroll(event: Element): number {
    return event.scrollTop;
  }

}
