import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base-rest.service';
import { AssociatedClientModel } from '../../models/associated-client.model';
import { AssociatedClientOptimizedModel } from 'src/app/models/associated-client-optimized.model';
import { BaseSearchQueryModel } from '../../models/base-search-query.model';
import { ClientModel } from 'src/app/models/client.model';
import { EnumModel } from 'src/app/models/enum.model';
import { environment } from 'src/environments/environment';
import { CopyClientInfoQuery } from 'src/app/models/copy-client-info-query';

@Injectable({
  providedIn: 'root'
})
export class AssociatedClientRestService extends BaseService {
  private SERVER_URL = environment.host + environment.basePathSolicitationApi;
  private CLIENT_PATH = '/client';
  private ASSOCIATED_CLIENT_PATH = '/associated-client';
  private SOLICITATION_PATH = '/solicitation';
  public emitter = new EventEmitter();
  public clientComponentEmitter = new EventEmitter();

  getById(id: number, optimized?: boolean): Observable<ClientModel | AssociatedClientOptimizedModel> {
    let suffix = '/' + id;
    if (optimized) { suffix += '?optimized=true'; }
    return this.httpClient.get<ClientModel | AssociatedClientOptimizedModel>(this.SERVER_URL + this.CLIENT_PATH + suffix, this.optionsSolicitationService);
  }

  getMainAssociatedClientBySolicitationId(solicitationId: number): Observable<AssociatedClientModel> {
    let suffix = '/' + solicitationId;
    return this.httpClient.get<AssociatedClientModel>(this.SERVER_URL + this.SOLICITATION_PATH + suffix + this.ASSOCIATED_CLIENT_PATH + "/-" + this.CLIENT_PATH, this.optionsSolicitationService);
  }

  findAdditionalAssociatedClientBySolicitationId(solicitationId: number): Observable<AssociatedClientModel[]> {
    let suffix = '/' + solicitationId;
    return this.httpClient.get<AssociatedClientModel[]>(this.SERVER_URL + this.SOLICITATION_PATH + suffix + this.ASSOCIATED_CLIENT_PATH, this.optionsSolicitationService);
  }

  findAssociatedClient(solicitationId: number, associatedClientTypeEnum?: EnumModel, optimized?: boolean): Observable<AssociatedClientModel[] | AssociatedClientOptimizedModel[]> {
    let suffix: string = null;
    if (solicitationId !== undefined && solicitationId !== null) {
      if (solicitationId !== undefined && solicitationId !== null) {
        suffix = '?solicitationId=' + solicitationId;
      }
      if (associatedClientTypeEnum !== undefined && associatedClientTypeEnum !== null) {
        suffix = (((suffix == null) ? '?' : (suffix + '&')) + 'associatedClientTypeEnum=' + associatedClientTypeEnum.description);
      }
      if (optimized !== undefined && optimized !== null) {
        suffix = (((suffix == null) ? '?' : (suffix + '&')) + 'optimized=' + optimized);
      }
    }
    return (suffix !== undefined && suffix !== null) ? this.httpClient.get<AssociatedClientModel[] | AssociatedClientOptimizedModel[]>(this.SERVER_URL + this.ASSOCIATED_CLIENT_PATH + "/-" + this.CLIENT_PATH + suffix, this.optionsSolicitationService) : null;
  }

  find(query: BaseSearchQueryModel): Observable<ClientModel[]> {
    let suffix: string = null;
    if (query !== undefined && query !== null) {
      if (query.rut !== undefined && query.rut !== null && query.rut !== '') {
        suffix = '?rut=' + query.rut;
        if (query.digit !== undefined && query.digit !== null && query.digit !== '') {
          suffix += '&digit=' + query.digit;
        }
      }
      if (query.name !== undefined && query.name !== null && query.name !== '') {
        suffix = (((suffix == null) ? '?' : (suffix + '&')) + 'name=' + query.name);
      }
      if (query.lastName !== undefined && query.lastName !== null && query.lastName !== '') {
        suffix = (((suffix == null) ? '?' : (suffix + '&')) + 'lastName=' + query.lastName);
      }
      if (query.lastNameMother !== undefined && query.lastNameMother !== null && query.lastNameMother !== '') {
        suffix = (((suffix == null) ? '?' : (suffix + '&')) + 'lastNameMother=' + query.lastNameMother);
      }
    }
    return (suffix !== undefined && suffix !== null) ? this.httpClient.get<ClientModel[]>(this.SERVER_URL + this.CLIENT_PATH + suffix, this.optionsSolicitationService) : null;
  }

  save(associatedClient: AssociatedClientModel): Observable<any> {
    return this.httpClient.post<any>(this.SERVER_URL + this.CLIENT_PATH, associatedClient, this.optionsSolicitationService);
  }

  delete(associatedClientId: number): Observable<any> {
    const suffix = '/' + associatedClientId;
    return this.httpClient.delete(this.SERVER_URL + this.CLIENT_PATH + this.ASSOCIATED_CLIENT_PATH + suffix, this.optionsSolicitationService);
  }

  findByRut(rut: string): Observable<AssociatedClientModel[]> {
    return this.httpClient.get<AssociatedClientModel[]>(this.SERVER_URL + this.ASSOCIATED_CLIENT_PATH + "/-/client-rut/" + rut, this.optionsSolicitationService);
  }

  copyClientInfo(copyClientInfoQuery: CopyClientInfoQuery): Observable<any> {
    return this.httpClient.post<any>(this.SERVER_URL + this.CLIENT_PATH + this.ASSOCIATED_CLIENT_PATH + "/-/copy-info", copyClientInfoQuery, this.optionsSolicitationService);
  }

  findAllAssociatedClients(solicitationId: number): Observable<any> {
    return this.httpClient.get<any>(this.SERVER_URL + this.ASSOCIATED_CLIENT_PATH + "/-/get-all/"+solicitationId, this.optionsSolicitationService);
  }
}
