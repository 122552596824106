import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base-rest.service';
import { DevelopmentTableRecalculatedModel } from '../../models/development-table-recalculated.model';
import { CovidParametersModel } from '../../models/covid-parameters.model';
import { CovidModel } from '../../models/covid.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DevelopmentTableRestService extends BaseService {
  private SERVER_URL = environment.host + environment.basePathDevelopmentTableApi;
  private RECALCULATE_TABLE_PATH = '/recalculate-table';

  calculate(mutualId: number): Observable<DevelopmentTableRecalculatedModel> {
    return this.httpClient.get<DevelopmentTableRecalculatedModel>(this.SERVER_URL + '/mutual/' + mutualId + this.RECALCULATE_TABLE_PATH, this.optionsDevelopmentTableService);
  }

  save(developmentTableRecalculatedModel: DevelopmentTableRecalculatedModel): Observable<DevelopmentTableRecalculatedModel> {
    return this.httpClient.post<DevelopmentTableRecalculatedModel>(this.SERVER_URL + this.RECALCULATE_TABLE_PATH, developmentTableRecalculatedModel, this.optionsDevelopmentTableService);
  }

  calculateCovid(covidQuery: CovidModel): Observable<CovidModel> {
    let query = '/mutual/-/covid/calculate?mutualId=' + covidQuery.mutualId +
      '&installment=' + covidQuery.installment +
      '&deferredPeriod=' + covidQuery.deferredPeriod +
      '&rate=' + covidQuery.rate +
      '&firstFinanced=' + covidQuery.firstFinanced +
      '&financedQuotas=' + covidQuery.financedQuotas;
    if (covidQuery.otherCharges != null) {
      query = query + '&other=' + covidQuery.otherCharges;
    }
    if (covidQuery.dividend != null) {
      query = query + '&dividend=' + covidQuery.dividend;
    }
    if (covidQuery.credit != null) {
      query = query + '&credit=' + covidQuery.credit;
    }
    return this.httpClient.get<CovidModel>(this.SERVER_URL + query, this.optionsDevelopmentTableService);
  }

  covidParameters(mutualId: number): Observable<CovidParametersModel> {
    const URL = '/mutuals/' + mutualId + '/covid/parameters';
    return this.httpClient.get<CovidParametersModel>(this.SERVER_URL + URL, this.optionsDevelopmentTableService);
  }

  saveCovid(covid: CovidModel): Observable<CovidModel> {
    return this.httpClient.post<CovidModel>(this.SERVER_URL + '/covid', covid, this.optionsDevelopmentTableService);
  }
}
