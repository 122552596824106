import { Injectable } from '@angular/core';
import * as moment from 'moment';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import { GenerateFileModel } from '../../models/generate-file.model';
import * as FileSaver from 'file-saver';
import { FileType } from '../../enums/file-type.enum';
import { Moment } from 'moment';
import { FileTypeEnumModel } from '../../models/file-type-enum.model';


@Injectable()
export class UtilService {

  public static regularExpressionsRut: any = {
    K: { pattern: new RegExp('[k,K,0-9]') },
    X: { pattern: new RegExp('[1-9]') },
    0: { pattern: new RegExp('[0-9]') }
  };

  public static getDV(inputRut: any): string {
    if (inputRut === null) {
      return null;
    }
    const rut = String(inputRut);
    let result = 0;
    let serie = 2;
    let mod: number;
    let vdv: number | string;

    for (let i = rut.length - 1; i >= 0; i--) {
      result += +rut.charAt(i) * serie;
      serie++;
      if (serie > 7) {
        serie = 2;
      }
    }

    mod = result % 11;
    vdv = 11 - mod;

    if (vdv === 11) {
      vdv = 0;
    }
    if (vdv === 10) {
      vdv = 'K';
    }

    return String(vdv);
  }


  public static validateRut(rutWithDV: string): boolean {
    const dv: string = rutWithDV.slice(-1).toUpperCase();
    const rut: string = rutWithDV.slice(0, -1);
    const validDV: string = this.getDV(rut);

    if (validDV === dv) {
      return true;
    } else {
      return null;
    }
  }

  public static getAge(date: string): number {
    const today: Moment = moment();
    const currentDay: number = today.date();
    const currentMonth = today.month() + 1;
    const currentYear: number = today.year();

    const birthday: Moment = moment(date);
    const birthDay: number = birthday.date();
    const birthMonth = birthday.month() + 1;
    const birthYear: number = birthday.year();

    let age = currentYear - birthYear;
    const differenceOfMonths = currentMonth - birthMonth;

    if (differenceOfMonths < 0 || (differenceOfMonths === 0 && currentDay < birthDay)) {
      age--;
    }

    return age;
  }

  public static formatRut(rut: string, digit: string): string {
    let formattedRut: string;
    if (rut?.length === 8) {
      const million: string = rut.slice(0, 2);
      const thousand: string = rut.slice(2, 5);
      const hundred: string = rut.slice(5, 8);
      formattedRut = `${million}.${thousand}.${hundred}-${digit}`;
    } else if (rut?.length === 7) {
      const million: string = rut.slice(0, 1);
      const thousand: string = rut.slice(1, 4);
      const hundred: string = rut.slice(4, 7);
      formattedRut = `${million}.${thousand}.${hundred}-${digit}`;
    } else {
      return null;
    }
    return formattedRut;
  }

  public static getSweetLoading(title?: string, text?: string): void {
    Swal.fire({
      title: title ? title : null,
      allowOutsideClick: false,
      text: text ? text : null
    });
    Swal.showLoading();
  }

  public static getSweetAlertTop(title: string, icon: SweetAlertIcon, timer?: number): Promise<SweetAlertResult<any>> {
    const toast: any = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: timer ? timer : 3000,
      timerProgressBar: true,
      didOpen: (t) => {
        t.addEventListener('mouseenter', Swal.stopTimer);
        t.addEventListener('mouseleave', Swal.resumeTimer);
        t.addEventListener('click', Swal.clickCancel);
      }
    });

    return toast.fire({
      icon,
      title
    });
  }

  public static getUnformattedNumber(value: string): number {
    let response: string;
    if (value !== undefined && value !== null) {
      response = String(value).replace('$', '');
      response = response.replace('%', '');
      response = response.replace('UF', '');
      response = response.replace('_', '');
      response = response.replace(' ', '');
      response = response.split('.').join('');
      response = response.split(',').join('.');

      if (isNaN(Number(response))) {
        response = null;
      }
    } else {
      response = null;
    }

    return Number(response);
  }

  public static downloadAnyFile(document: GenerateFileModel, fileName?: string): void {
    const byteCharacters: string = atob(document.file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    let contentType: string;
    if (document.contentType !== undefined && document.contentType !== null) {
      contentType = document.contentType;
    } else if (document.extension !== undefined && document.extension !== null) {
      const fileTypeFound: FileTypeEnumModel = new FileType().FILE_TYPE.find(fileType => fileType.extension === document.extension);
      contentType = fileTypeFound.contentType;
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: contentType
    });

    FileSaver.saveAs(blob, fileName ? fileName : document.name);
  }
}
