import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/authentication/services/authentication.service';

import { SSO_INTERNAL, SSO_EXTERNAL, SSO_INTERNAL_USER_INFO, SSO_EXTERNAL_USER_INFO, API_TMC_URL, API_UF_URL, API_UTM_URL, SECURITY_INITIAL_PAGE } from 'src/app/config/globals-constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
    private INTERNAL_URL = SSO_INTERNAL;
    private EXTERNAL_URL = SSO_EXTERNAL;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authenticationService.token;
        if (request.url != this.INTERNAL_URL
            && request.url != this.EXTERNAL_URL
            && request.url != SSO_INTERNAL_USER_INFO
            && request.url != SSO_EXTERNAL_USER_INFO
            && request.url != API_UF_URL
            && request.url != API_UTM_URL
            && request.url != API_TMC_URL
            && request.url != SECURITY_INITIAL_PAGE
            && token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token.access_token}`
                }
            });
        }

        return next.handle(request);
    }
}
