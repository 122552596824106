<ng-container *ngIf="loading">
  <div class="card">
    <div class="card-body">
      <div class="text-center">
        <div class="grid-circles">
          <div class="circles">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <p class="m-0 mb-3" style="color: #1d3f72;"><strong>CARGANDO</strong></p>
      </div>
    </div>
  </div>
</ng-container>