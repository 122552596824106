import { Component , OnInit} from '@angular/core';
import {NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  constructor(private readonly ngbModalConfig: NgbModalConfig) {
    ngbModalConfig.container = 'body';
  }

  ngOnInit(): void {
  }
}
