<div class="modal-header" style="background-color: #F8F8FB;">

  <h4 class="modal-title">
    Mutuo - {{mutualCode}}
  </h4>
  <button class="btn-close" type="button" aria-hidden="true" (click)="modal.close()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-8 text-start">
      <h4><i class="mdi mdi-clipboard-text-search-outline mdi-18px me-1"></i>Tabla Propuesta</h4>
    </div>
    <div class="col-4 text-end">
      <button class="btn btn-success button-table me-2" (click)="calculate(mutualId)">
        <i class="bx bx-duplicate label-icon"></i> Calcular
      </button>
      <button class="btn btn-info button-table" (click)="save()">
        <i class="bx bx-duplicate label-icon"></i> Guardar
      </button>
    </div>

  </div>
  <div class="container">
    <mat-table mat-table [dataSource]="dataSource" matSort matSo rtActive="id" matSortDirection="desc">
      <ng-container matColumnDef="number">
        <mat-header-cell *matHeaderCellDef mat-sort-header>N° Cuota</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="N° Cuota">
          {{element.number | number}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="expiration">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Vencimiento</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Vencimiento">
          {{element.expirationDate | date:'dd/MM/yyyy':'UTC'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="payment">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Pago</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Numero">
          {{element.paymentDate | date:'dd/MM/yyyy':'UTC'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="capital">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Capital</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Comuna">
          {{element.capital | number:'1.4-4'}} UF
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="interest">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Interés</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="region">
          {{element.interest | number:'1.4-4'}} UF
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dividend">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Dividendo</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="region">
          {{element.dividend | number:'1.4-4'}} UF
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="outstandingBalance">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Saldo Insoluto</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="region">
          {{element.outstandingBalance | number:'1.4-4'}} UF
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="amortisation">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Amortización Acumulada</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="region">
          {{element.amortisation | number:'1.4-4'}} UF
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="loading">
        <mat-footer-cell *matFooterCellDef>
          <div class="spinner-border m-10" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <strong> Cargando...</strong>
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef colspan="6">
          Sin datos
        </mat-footer-cell>
      </ng-container>
      <mat-header-row mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
      <mat-row mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
      <mat-footer-row *matFooterRowDef="['loading']" [hidden]="dataSource!==null"></mat-footer-row>
      <mat-footer-row *matFooterRowDef="['noData']" [hidden]="!(dataSource!==null && dataSource.data.length===0)">
      </mat-footer-row>
    </mat-table>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" (click)="modal.close('Close click')">Cerrar</button>
</div>
