
import { environment } from "src/environments/environment";

const BASE_URL = environment.host;
export const SIMULATOR_BACK_URL = BASE_URL + environment.basePathSimulatorApi;
export const SOLICITATION_URL = BASE_URL + environment.basePathSolicitationApi;
export const INSURANCE_URL = BASE_URL + environment.basePathInsurancePolicyApi;
export const DEVELOPMENT_TABLE_URL = BASE_URL + environment.basePathDevelopmentTableApi;
export const JUDICIAL_LIQUIDATION_URL = BASE_URL + environment.basePathJudicialLiquidationApi;
export const MUTUAL_URL = BASE_URL + environment.basePathMutualApi;
export const PROFILE_URL = BASE_URL + environment.basePathProfileApi;
export const SECURITY_INITIAL_PAGE = environment.homeRoute;

export const SSO_BASE_URL = environment.SSOBaseUrl;

export const SSO_INTERNAL = SSO_BASE_URL + 'HS-Internals/protocol/openid-connect/token';
export const SSO_EXTERNAL = SSO_BASE_URL + 'HS-Externals/protocol/openid-connect/token';
export const SSO_INTERNAL_USER_INFO = SSO_BASE_URL + 'HS-Internals/protocol/openid-connect/userinfo?grant_type=refresh_token';
export const SSO_EXTERNAL_USER_INFO = SSO_BASE_URL + 'HS-Externals/protocol/openid-connect/userinfo?grant_type=refresh_token';

export const API_UF_URL = 'https://api.sbif.cl/api-sbifv3/recursos_api/uf?apikey=eeca503d1a029d14951c5af43ca9302729daf297&formato=json';
export const API_UTM_URL = 'https://api.sbif.cl/api-sbifv3/recursos_api/utm?apikey=eeca503d1a029d14951c5af43ca9302729daf297&formato=json';
export const API_TMC_URL = 'https://api.sbif.cl/api-sbifv3/recursos_api/tmc?apikey=eeca503d1a029d14951c5af43ca9302729daf297&formato=json';

export const LOGIN_ERROR = environment.loginErrorRoute;
export const UTM_ERROR = environment.cmfErrorRoute;

export const THOUSANDS_SEPARATOR_SYMBOL = '.';
export const DECIMAL_SYMBOL = ',';
export const DECIMAL_LIMIT = 4;
export const INTERGER_LIMIT = 9;
export const DECIMAL_LIMIT_PERCENTAGE = 2;
export const INTERGER_LIMIT_PERCENTAGE = 3;
export const RUT_MASK = 'X.000.000-K||X0.000.000-K';
export const CELLPHONE_MASK = '00-000-000';
