<div id="layout-wrapper" >
  <app-horizontaltopbar></app-horizontaltopbar>

  <div class="main-content">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>


</div>
<div class="footer">
  <app-footer></app-footer>
</div>
